/* eslint-disable react/prop-types */
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import Layout from "@layout";
import { normalizedData } from "@utils/functions";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
import Image from "./../../data/images/blog/Engi@4x.png"; 

const creditRating = ({ data }) => {
    const [preload, setPreload] = useState(true);
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);

    useEffect(() => {
        setPreload(false);
    }, []);

    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <Container>
                {/* You can start code here */}

                <h1 style={{ textAlign: "center" }}>Credit Rating Report</h1>
                {/* <p style={{ textAlign: "center" }}>This is your paragraph</p> 
                <img src={Image} alt='#' style={{ marginBottom: "20px" }} /> */}
<h4 color="black"> 
<img src = "/home/rony/Desktop/crisl.jpg"></img>
# This is a test page </h4>

<h4 color="black">#This is a test page </h4>

<h4>#This is a test page
</h4>

            </Container>
        </Layout>
    );
};

export const query = graphql`
    query creditRatingQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default creditRating;
